import './Listen.css';

function Listen() {
    return (
        <div className="App">
            <div >
                <div className="site-banner-header">
                    <h1 >
                        Listen
                    </h1>
                </div>
                <div class="row">
         {// eslint-disable-next-line
}<iframe src="https://open.spotify.com/embed/album/6rHkKtc2Zl1a0sA2Dz2gTz" width="100%" height="450" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
                  
                </div>
            </div>
        );
      }
      
      export default Listen;
      
